
import { defineComponent, reactive, onBeforeMount, ref, Ref, computed, nextTick } from 'vue';
import Post from '@/components/PublicPost.vue';
import PostEntry from '@/components/PostEntry.vue';
import RoomList from '@/components/RoomList.vue';
import ReactionConversation from '@/components/Reactions/ReactionConversation.vue';
import router from '@/router/index';
import { db, auth, dbUtils } from '@/firebase';

const Foyer = defineComponent({
  components: {
    Post,
    PostEntry,
    RoomList,
    ReactionConversation,
  },

  setup() {
    const collectionName: Ref<string> = ref('');
    /** Used to get the name to be used when posting to or reading from the collection */
    const collectionNameFormatted = computed(() => `${collectionName.value[0].toLowerCase()}${collectionName.value.slice(1)}`);
    const state = reactive({
      roomName: '',
      posts: [],
    });

    function handleNewPost(ev: UIEvent): void {
      db.collection(collectionNameFormatted.value)
        .add({
          content: ev,
          author: auth.currentUser?.uid ?? `unknown`,
          postedOn: dbUtils.FieldValue.serverTimestamp(),
        })
        .then(result => result)
        .catch(err => console.error(err));
    }

    function getPageData(): void {
      state.roomName = router.currentRoute.value.meta.room;
      collectionName.value = router.currentRoute.value.name?.toString() ?? '';
      const posts = db.collection(collectionNameFormatted.value)
        .limit(25)
        .orderBy('postedOn', 'desc')
        .onSnapshot(snapshot => {
          // @ts-expect-error
          state.posts = snapshot.docs
            .map(doc => doc.data());
        });
    }

    onBeforeMount(() => {
      getPageData();
    });

    return {
      state,
      handleNewPost,
    }
  },
});

export default Foyer;
