<template>
	<article class="foyer">
    <aside class="foyer__rooms">
      <RoomList />
    </aside>
    <section class="foyer__post-container">
      <h1 class="foyer__heading o-heading">The {{ state.roomName }}</h1>
      <PostEntry @add-post="handleNewPost" />
      <section class="foyer__posts">
        <template v-if="state.posts.length > 0">
          <Post
            v-for="post in state.posts"
            :key="post?.author"
            :author="post?.author"
            :content="post?.content"
            :posted-on="post?.postedOn?.toDate()"
          />
        </template>
        <template v-else>
          <div class="foyer__no-threads">
            <p>No one has posted yet. Start a conversation!</p>
            <ReactionConversation class="foyer__no-threads-illustration" />
          </div>
        </template>
      </section>
    </section>
	</article>
</template>

<script lang="ts">
import { defineComponent, reactive, onBeforeMount, ref, Ref, computed, nextTick } from 'vue';
import Post from '@/components/PublicPost.vue';
import PostEntry from '@/components/PostEntry.vue';
import RoomList from '@/components/RoomList.vue';
import ReactionConversation from '@/components/Reactions/ReactionConversation.vue';
import router from '@/router/index';
import { db, auth, dbUtils } from '@/firebase';

const Foyer = defineComponent({
  components: {
    Post,
    PostEntry,
    RoomList,
    ReactionConversation,
  },

  setup() {
    const collectionName: Ref<string> = ref('');
    /** Used to get the name to be used when posting to or reading from the collection */
    const collectionNameFormatted = computed(() => `${collectionName.value[0].toLowerCase()}${collectionName.value.slice(1)}`);
    const state = reactive({
      roomName: '',
      posts: [],
    });

    function handleNewPost(ev: UIEvent): void {
      db.collection(collectionNameFormatted.value)
        .add({
          content: ev,
          author: auth.currentUser?.uid ?? `unknown`,
          postedOn: dbUtils.FieldValue.serverTimestamp(),
        })
        .then(result => result)
        .catch(err => console.error(err));
    }

    function getPageData(): void {
      state.roomName = router.currentRoute.value.meta.room;
      collectionName.value = router.currentRoute.value.name?.toString() ?? '';
      const posts = db.collection(collectionNameFormatted.value)
        .limit(25)
        .orderBy('postedOn', 'desc')
        .onSnapshot(snapshot => {
          // @ts-expect-error
          state.posts = snapshot.docs
            .map(doc => doc.data());
        });
    }

    onBeforeMount(() => {
      getPageData();
    });

    return {
      state,
      handleNewPost,
    }
  },
});

export default Foyer;
</script>

<style lang="scss" scoped>
.foyer {
  display: grid;
  grid-template: "sidebar content" 1fr / minmax(250px, 25%) 1fr;
  gap: 3rem;
  @media (max-width: 992px) {
    grid-template:
      "sidebar" auto
      "content" 1fr / 1fr;
  }
  &__rooms {
    grid-area: sidebar;
    padding-top: 10rem;
    @media (max-width: 992px) {
      padding-top: 0;
    }
  }
  &__post-container {
    grid-area: content;
    display: grid;
    grid-template: auto auto 1fr / 1fr;
    align-items: start;
    height: 100%;
    overflow-y: auto;
  }
  &__heading {
    text-align: left;
  }
  &__posts,
  &__no-threads {
    display: grid;
    grid-auto-rows: auto;
    gap: 3rem;
  }
  &__no-threads {
    justify-items: center;
    &-illustration {
      width: 33%;
      height: auto;
      &:hover {
        transform: none;
      }
    }
  }
}
</style>