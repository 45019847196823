
import { defineComponent, ComputedRef, computed, Ref, ref, onMounted } from 'vue';
import { db, auth } from '@/firebase';

export const Post = defineComponent({
  props: {
    content: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    postedOn: {
      type: Date,
    },
  },

  setup(props) {
    // const dateString: ComputedRef<string> = computed(() => (new Date(props.postedOn).toDateString()));
    const authorName: Ref<string> = ref('');
    const authorInitials: Ref<string> = ref('');
    const avitarColor: Ref<string> = ref('');
    const postOwner: ComputedRef<boolean> = computed(() => props.author === auth.currentUser?.uid);

    onMounted(async () => {
      try {
        if (!!props.author) {
          authorName.value = await db.collection(`users`)
            .where('uid', '==', props.author)
            .get()
            .then(snapshot => {
              if (snapshot.docs.length === 1) {
                const { firstName, lastName, initials, avitarBackground } = snapshot.docs[0].data();
                authorInitials.value = initials;
                avitarColor.value = avitarBackground ?? 'var(--primaryAlt)';

                return `${firstName} ${lastName}`;
              } else {
                return `Anonymous`;
              }
            });
        }
      } catch (err) {
        return `Anonymous`;
      }
    })

    return {
      // dateString,
      authorName,
      authorInitials,
      avitarColor,
      postOwner,
    }
  }
});

export default Post;
