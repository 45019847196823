
import { defineComponent, ref, Ref } from 'vue';
import BaseButton from '@/components/BaseButton.vue';

const PostEntry = defineComponent({
  emits: ['add-post'],

  setup(props, { emit }) {
    const postContent: Ref<string> = ref('');
    const input: Ref<HTMLTextAreaElement | null> = ref(null);
    const isFocused: Ref<boolean> = ref(false);

    const addPost = () => {
      emit('add-post', input.value?.value ?? '');
      postContent.value = '';
    };

    return {
      postContent,
      input,
      addPost,
      isFocused,
    }
  },
});

export default PostEntry;
