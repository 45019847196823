
import { defineComponent, onMounted, Ref, ref } from 'vue';
import router from '@/router/index';
import { RouteRecord } from 'vue-router';

export default defineComponent({
  setup() {
    const roomRoutes: Ref<RouteRecord[]> = ref([]);

    function goToRoom(path: string): void {
      router.push(path);
    }

    onMounted(() => {
      roomRoutes.value = router.getRoutes().filter(route => !!route.meta.room);
    });


    return {
      roomRoutes,
      goToRoom,
    }
  }
})
