<template>
	<nav
    class="rooms"
    aria-labelledby="roomListHeading"
  >
    <router-link
      class="rooms__heading o-heading rooms__link"
      to="about"
    >
      About
    </router-link>
    <h3
      id="roomListHeading"
      class="rooms__heading o-heading"
    >Rooms</h3>
		<ul class="rooms__list">
			<li
				v-for="(route, index) in roomRoutes"
				:key="index"
				class="rooms__list-item"
			>
				<router-link
					:to="route.path"
					:active-class="'is--active'"
					class="rooms__link"
				>
					{{ route.meta.room }}
				</router-link>
			</li>
		</ul>
	</nav>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from 'vue';
import router from '@/router/index';
import { RouteRecord } from 'vue-router';

export default defineComponent({
  setup() {
    const roomRoutes: Ref<RouteRecord[]> = ref([]);

    function goToRoom(path: string): void {
      router.push(path);
    }

    onMounted(() => {
      roomRoutes.value = router.getRoutes().filter(route => !!route.meta.room);
    });


    return {
      roomRoutes,
      goToRoom,
    }
  }
})
</script>

<style lang="scss" scoped>
.rooms {
  text-align: left;
  border: 1px solid var(--lightGrey, #eee);
  border-radius: 1.2rem;
  box-shadow: 1px 1px 8px var(--lightGrey, #eee);
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  background: var(--white);
  // @media (max-width: 992px) {
  //   height: 62px;
  //   overflow: hidden;
  // }
  &__heading {
    font-variation-settings: "wght" 900;
    font-size: 2rem;
  }
  &__list {
    list-style-type: none;
    padding-left: 0;
    &-item {
      padding: 1rem 0;
      &:not(:first-of-type) {
        border-top: 1px solid var(--lightGrey, #eee);
      }
    }
  }
  &__link {
    text-decoration: none;
    &.is {
      &--active {
        text-decoration: underline;
      }
    }
  }
}
</style>