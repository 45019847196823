<template>
	<article class="post">
    <ReactionDelete
      class="post__delete"
      v-if="postOwner"
    />
		<section class="post__heading">
			<section class="post__heading-text">
        <span
          class="post__heading-avitar"
          :style="{
            'background': avitarColor,
          }"
        >{{ authorInitials }}</span>
        <section class="post__heading-post-data">
          <span class="post__heading-author">{{ authorName }}</span>
          <span class="post__heading-date">{{ postedOn?.toLocaleString() }}</span>
        </section>
			</section>
		</section>
		<section class="post__content">
			<p class="post__content-text">
				{{ content }}
			</p>
		</section>
	</article>
</template>

<script lang="ts">
import { defineComponent, ComputedRef, computed, Ref, ref, onMounted } from 'vue';
import { db, auth } from '@/firebase';

export const Post = defineComponent({
  props: {
    content: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    postedOn: {
      type: Date,
    },
  },

  setup(props) {
    // const dateString: ComputedRef<string> = computed(() => (new Date(props.postedOn).toDateString()));
    const authorName: Ref<string> = ref('');
    const authorInitials: Ref<string> = ref('');
    const avitarColor: Ref<string> = ref('');
    const postOwner: ComputedRef<boolean> = computed(() => props.author === auth.currentUser?.uid);

    onMounted(async () => {
      try {
        if (!!props.author) {
          authorName.value = await db.collection(`users`)
            .where('uid', '==', props.author)
            .get()
            .then(snapshot => {
              if (snapshot.docs.length === 1) {
                const { firstName, lastName, initials, avitarBackground } = snapshot.docs[0].data();
                authorInitials.value = initials;
                avitarColor.value = avitarBackground ?? 'var(--primaryAlt)';

                return `${firstName} ${lastName}`;
              } else {
                return `Anonymous`;
              }
            });
        }
      } catch (err) {
        return `Anonymous`;
      }
    })

    return {
      // dateString,
      authorName,
      authorInitials,
      avitarColor,
      postOwner,
    }
  }
});

export default Post;
</script>

<style lang="scss" scoped>
.post {
  position: relative;
  display: grid;
  grid-template:
    "heading" auto
    "content" 1fr / 1fr;
  width: 100%;
  background: var(--white, #fff);
  border: 1px solid var(--grey, #f1f2f4);
  border-radius: 1.5rem;
  padding: 1.5rem 3rem;
  box-shadow: 1px 1px 8px var(--grey, #eee);
  text-align: left;
  color: var(--richBlack, #333);
  &__heading {
    display: flex;
    flex-flow: column nowrap;
    &-text {
      display: grid;
      grid-template: "avitar postInfo" auto / auto auto;
      justify-content: start;
      font-size: 1.2rem;
    }
    &-avitar {
      display: grid;
      place-items: center;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      margin-right: 0.5rem;
      background: var(--primaryAlt);
      color: var(--white);
      font-size: 1.6rem;
      font-weight: 700;
    }
    &-author,
    &-date {
      display: flex;
      font-weight: 600;
    }
  }
  &__delete {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
</style>