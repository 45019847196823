<template>
	<section class="post-entry">
    <div role="none" class="post-entry__input-wrapper">
      <textarea
        ref="input"
        v-model="postContent"
        name="postEntry"
        id="postEntry"
        :class="{
          'post-entry__input': true,
          'is--focused': isFocused,
        }"
        :style="{
          'height': isFocused ? '150px' : '52px',
        }"
        @focus="isFocused = true"
        @blur="isFocused = false"
      ></textarea>
      <ReactionSend
        tabindex="0"
        title="Create Post"
        class="post-entry__send"
        @keydown.enter="addPost"
        @keydown.space.prevent="addPost"
        @click="addPost"
      />
    </div>
	</section>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import BaseButton from '@/components/BaseButton.vue';

const PostEntry = defineComponent({
  emits: ['add-post'],

  setup(props, { emit }) {
    const postContent: Ref<string> = ref('');
    const input: Ref<HTMLTextAreaElement | null> = ref(null);
    const isFocused: Ref<boolean> = ref(false);

    const addPost = () => {
      emit('add-post', input.value?.value ?? '');
      postContent.value = '';
    };

    return {
      postContent,
      input,
      addPost,
      isFocused,
    }
  },
});

export default PostEntry;
</script>

<style lang="scss" scoped>
.post-entry {
  margin-bottom: 3rem;
  &__input {
    width: 100%;
    resize: none;
    border: 1px solid var(--lightGrey, #b2b2b2);
    border-radius: 1.2rem;
    padding: 10px;
    font-family: var(--fontCopy);
    letter-spacing: 1px;
    word-spacing: 2px;
    transition: height 250ms ease-in-out;
    outline: none;
    &-wrapper {
      position: relative;
    }
    &.is {
      &--focused {
        border-color: var(--primaryAlt);
      }
    }
  }
  &__send {
    position: absolute;
    bottom: 13px;
    right: 15px;
  }
}
</style>